/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Activation } from '../models/Activation';
import type { Credentials } from '../models/Credentials';
import type { CustomerPortalUser_user_view } from '../models/CustomerPortalUser_user_view';
import type { CustomerPortalUser_user_view_self } from '../models/CustomerPortalUser_user_view_self';
import type { CustomerPortalUser_user_view_self_oauth } from '../models/CustomerPortalUser_user_view_self_oauth';
import type { PasswordReset } from '../models/PasswordReset';
import type { PasswordResetRequest } from '../models/PasswordResetRequest';
import type { Registration } from '../models/Registration';
import type { WebshopRegistration } from '../models/WebshopRegistration';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * Retrieves a CustomerPortalUser resource.
     * Retrieves a CustomerPortalUser resource.
     * @returns CustomerPortalUser_user_view_self_oauth CustomerPortalUser resource
     * @throws ApiError
     */
    public static meOauthCustomerPortalUserItem({
        uuid,
    }: {
        /**
         * Resource identifier
         */
        uuid: string,
    }): CancelablePromise<CustomerPortalUser_user_view_self_oauth> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customer_portal/oauth/me',
            path: {
                'uuid': uuid,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Retrieves the collection of CustomerPortalUser resources.
     * Retrieves the collection of CustomerPortalUser resources.
     * @returns CustomerPortalUser_user_view CustomerPortalUser collection
     * @throws ApiError
     */
    public static getCustomerPortalUserCollection({
        page = 1,
        amount = 30,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        /**
         * The number of items per page
         */
        amount?: number,
    }): CancelablePromise<Array<CustomerPortalUser_user_view>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customer_portal/users',
            query: {
                'page': page,
                '_amount': amount,
            },
        });
    }

    /**
     * Retrieves a CustomerPortalUser resource.
     * Retrieves a CustomerPortalUser resource.
     * @returns CustomerPortalUser_user_view_self CustomerPortalUser resource
     * @throws ApiError
     */
    public static meCustomerPortalUserItem(): CancelablePromise<CustomerPortalUser_user_view_self> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customer_portal/users/me',
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Register a new user
     * Registers a new user
     * @returns string Created
     * @throws ApiError
     */
    public static registerRegistrationCollection({
        requestBody,
    }: {
        /**
         * The new Registration resource
         */
        requestBody: Registration,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customer_portal/users/register',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Email already in use`,
            },
        });
    }

    /**
     * Activate a new user
     * Activates a new user
     * @returns any Created
     * @throws ApiError
     */
    public static registerActivationCollection({
        requestBody,
    }: {
        /**
         * The new Activation resource
         */
        requestBody: Activation,
    }): CancelablePromise<{
        status?: string;
        token?: string;
        refresh_token?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customer_portal/users/register/activate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Email already in use`,
            },
        });
    }

    /**
     * Request a user password reset
     * Generate a password reset token and e-mail the code/link to the user
     * @returns string Created
     * @throws ApiError
     */
    public static passwordresetRequestPasswordResetRequestCollection({
        requestBody,
    }: {
        /**
         * The new PasswordResetRequest resource
         */
        requestBody: PasswordResetRequest,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customer_portal/users/reset_password/request',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Reset a user password
     * Reset the password to the given password, using a valid token
     * @returns string Created
     * @throws ApiError
     */
    public static passwordresetResetPasswordResetCollection({
        requestBody,
    }: {
        /**
         * The new PasswordReset resource
         */
        requestBody: PasswordReset,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customer_portal/users/reset_password/reset',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Not found`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Update your credentials
     * Update your credentials (email and/or password)
     * @returns any Ok
     * @throws ApiError
     */
    public static registerCredentialsCollection({
        requestBody,
    }: {
        /**
         * The new Credentials resource
         */
        requestBody: Credentials,
    }): CancelablePromise<{
        status?: string;
        token?: string;
        refresh_token?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customer_portal/users/update_credentials',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                403: `Invalid password`,
            },
        });
    }

    /**
     * Register a new webshop user
     * Registers a new webshop user
     * @returns any Created
     * @throws ApiError
     */
    public static registerWebshopRegistrationCollection({
        requestBody,
    }: {
        /**
         * The new WebshopRegistration resource
         */
        requestBody: WebshopRegistration,
    }): CancelablePromise<{
        status?: string;
        token?: string;
        refresh_token?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customer_portal/users/webshop/register',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Email already in use`,
            },
        });
    }

    /**
     * Retrieves a CustomerPortalUser resource.
     * Retrieves a CustomerPortalUser resource.
     * @returns CustomerPortalUser_user_view CustomerPortalUser resource
     * @throws ApiError
     */
    public static getCustomerPortalUserItem({
        uuid,
    }: {
        /**
         * Resource identifier
         */
        uuid: string,
    }): CancelablePromise<CustomerPortalUser_user_view> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customer_portal/users/{uuid}',
            path: {
                'uuid': uuid,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

}
