import 'pages/global.css';
import type { AppProps } from 'next/app';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { getTokens, removeTokens, saveTokens, TokenContextProvider } from 'providers/TokenProvider';
import { ApiError, refreshTokens, USE_ME_QUERY_KEY } from 'helpers/api';
import { captureException } from 'helpers/captureException';
import { UserProvider } from 'providers/UserProvider';
import { setAppElement } from 'components/ui/Modal';

setAppElement('#__next');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (failureCount > 0) {
          return false;
        }

        const isUnauthorizedError = error instanceof ApiError && error.status === 401;
        return isUnauthorizedError;
      },
    },
  },
  queryCache: new QueryCache({
    onError: async (error) => {
      const isUnauthorizedError = error instanceof ApiError && error.status === 401;
      const tokens = getTokens();

      if (isUnauthorizedError && tokens?.refreshToken) {
        try {
          const response = await refreshTokens({ refresh_token: tokens.refreshToken });

          if (response.token && response.refresh_token) {
            saveTokens(response.token, response.refresh_token);
            await queryClient.refetchQueries([USE_ME_QUERY_KEY]);
          } else {
            removeTokens();
            queryClient.resetQueries();
          }
        } catch {
          captureException(error);
          removeTokens();
          queryClient.resetQueries();
        }
      }
    },
  }),
});

const MyApp = ({ Component, pageProps }: AppProps) => (
  <QueryClientProvider client={queryClient}>
    <TokenContextProvider>
      <UserProvider>
        <Component {...pageProps} />
      </UserProvider>
    </TokenContextProvider>
  </QueryClientProvider>
);

export default MyApp;
