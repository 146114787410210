import React, { createContext, useContext, useEffect, useState } from 'react';

type TokenType = {
  accessToken?: string;
  refreshToken?: string;
};

type TokenContextType = TokenType & {
  loading: boolean;
};

const initial: TokenContextType = {
  loading: true,
  accessToken: undefined,
  refreshToken: undefined,
};

const TokenContext = createContext<TokenContextType>(initial);

const ACCESS_TOKEN_LOCAL_STORAGE_KEY = 'VISSER_AT';
const REFRESH_TOKEN_LOCAL_STORAGE_KEY = 'VISSER_RT';

const UDPATE_TOKEN_EVENT_KEY = 'visser-update-tokens';

export const getTokens = (): TokenType => {
  if (typeof window === 'undefined') return { accessToken: undefined, refreshToken: undefined };

  const accessToken = window.localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
  const refreshToken = window.localStorage.getItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY);

  const token = { accessToken: accessToken || undefined, refreshToken: refreshToken || undefined };

  window.dispatchEvent(new CustomEvent<TokenType>(UDPATE_TOKEN_EVENT_KEY, { detail: token }));

  return token;
};

export const saveTokens = (accessToken: string, refreshToken: string) => {
  if (typeof window === 'undefined') return;

  window.localStorage.setItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, accessToken);
  window.localStorage.setItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY, refreshToken);

  const token = { accessToken: accessToken || undefined, refreshToken: refreshToken || undefined };

  window.dispatchEvent(new CustomEvent<TokenType>(UDPATE_TOKEN_EVENT_KEY, { detail: token }));
};

export const removeTokens = () => {
  if (typeof window === 'undefined') return;

  window.localStorage.removeItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
  window.localStorage.removeItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY);

  window.dispatchEvent(new CustomEvent<TokenType>(UDPATE_TOKEN_EVENT_KEY, { detail: undefined }));
};

export const TokenContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [tokens, setTokens] = useState<TokenContextType>(initial);

  useEffect(() => {
    setTokens({
      ...getTokens(),
      loading: false,
    });
    const update = (event: CustomEvent<TokenType>) => {
      setTokens({
        ...event.detail,
        loading: false,
      });
    };
    window.addEventListener(UDPATE_TOKEN_EVENT_KEY, update as EventListener);
    return () => window.removeEventListener(UDPATE_TOKEN_EVENT_KEY, update as EventListener);
  }, []);

  return <TokenContext.Provider value={tokens}>{children}</TokenContext.Provider>;
};

export const useTokens = () => useContext(TokenContext);
