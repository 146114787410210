import { getTokens } from 'providers/TokenProvider';
import { UserService } from 'generated/api/services/UserService';
import { MyCustomersService } from 'generated/api/services/MyCustomersService';
import { MyOrdersService } from 'generated/api/services/MyOrdersService';
import { MyAppointmentsService } from 'generated/api/services/MyAppointmentsService';
import { AuthenticationService } from 'generated/api/services/AuthenticationService';
import { OpenAPI } from 'generated/api/core/OpenAPI';
import { Activation } from 'generated/api/models/Activation';
import { CustomerPortalJWTAuth } from 'generated/api/models/CustomerPortalJWTAuth';
import { CustomerPortalJWTRefresh } from 'generated/api/models/CustomerPortalJWTRefresh';
import { Registration } from 'generated/api/models/Registration';
import { PasswordResetRequest } from 'generated/api/models/PasswordResetRequest';
import { PasswordReset } from 'generated/api/models/PasswordReset';
import { Credentials } from 'generated/api/models/Credentials';
import { CancelRequest } from 'generated/api/models/CancelRequest';
import { useQuery } from '@tanstack/react-query';
import { captureException } from 'helpers/captureException';
import { getQueryStringForOptions } from './getQueryStringForOptions';
import { BrightplanService } from 'generated/api';

export const USE_ME_QUERY_KEY = 'useMe';
export const USE_CUSTOMERS_QUERY_KEY = 'useCustomers';
export const USE_CUSTOMER_QUERY_KEY = 'useCustomer';

OpenAPI.BASE = process.env.NEXT_PUBLIC_API_URL || '';
OpenAPI.TOKEN = async (requestOptions) => {
  //todo: remove this when api skeleton is updated for this project
  if (requestOptions.url.startsWith('/customer_portal/jwt')) {
    return '';
  }

  return getTokens()?.accessToken || '';
};

const FIFTY_MINUTES = 1000 * 60 * 50;

export const useMe = (hasToken: boolean) =>
  useQuery([USE_ME_QUERY_KEY], () => UserService.meCustomerPortalUserItem(), {
    retry: false,
    staleTime: FIFTY_MINUTES,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: hasToken,
  });

export const useCustomers = (hasToken: boolean) =>
  useQuery(['customers'], () => MyCustomersService.myCustomersCustomerCollection({}), {
    retry: false,
    staleTime: FIFTY_MINUTES,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: hasToken,
  });

export const useCustomer = (code: string | number | undefined) =>
  useQuery(['customer', code], () => MyCustomersService.myCustomerCustomerItem({ code: code?.toString() || '' }), {
    retry: false,
    staleTime: FIFTY_MINUTES,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: code !== undefined,
  });

export const useCustomerOrders = (code: string | number | undefined) =>
  useQuery(
    ['customer', 'orders', code],
    () => MyOrdersService.portalOrdersCustomerItem({ code: code?.toString() || '' }),
    {
      retry: false,
      staleTime: FIFTY_MINUTES,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled: code !== undefined,
    }
  );

export const getBrightplanSSOLink = (customerCode: string) =>
  BrightplanService.generateRequestCollection({ requestBody: { customerCode } });

export const loginTokens = ({ email, password }: CustomerPortalJWTAuth) =>
  AuthenticationService.jwtToken({ requestBody: { email, password } });

export const refreshTokens = ({ refresh_token }: CustomerPortalJWTRefresh) =>
  AuthenticationService.jwtRefresh({ requestBody: { refresh_token } });

export const register = ({ email }: Registration) =>
  UserService.registerRegistrationCollection({ requestBody: { email } });

export const activateAccount = ({ password, code }: Activation) =>
  UserService.registerActivationCollection({ requestBody: { password, code } });

export const requestPasswordReset = ({ email }: PasswordResetRequest) =>
  UserService.passwordresetRequestPasswordResetRequestCollection({ requestBody: { email } });

export const resetPassword = ({ password, code }: PasswordReset) =>
  UserService.passwordresetResetPasswordResetCollection({ requestBody: { password, code } });

export const updateCredentials = ({ currentPassword, newPassword }: Credentials) =>
  UserService.registerCredentialsCollection({ requestBody: { currentPassword, newPassword } });

export const updateCustomer = ({ code, phoneNumber }: { code: string; phoneNumber: string }) =>
  MyCustomersService.putMyCustomerCustomerItem({ code, requestBody: { phoneNumber } });

export const cancelAppointment = (cancelRequest: CancelRequest) =>
  MyAppointmentsService.cancelCancelRequestCollection({ requestBody: cancelRequest });

export const useAutorization = (
  {
    scope,
    responseType,
    redirectUri,
    clientId,
    state,
  }: {
    scope?: string;
    responseType?: string;
    redirectUri?: string;
    clientId?: string;
    state?: string;
  },
  enabled: boolean
) =>
  useQuery(
    ['useAuthorization'],
    async () => {
      try {
        if (!responseType || !clientId) throw new Error('responseType and clientId are required');

        const tokens = getTokens();

        const queryParameters = getQueryStringForOptions({
          response_type: responseType,
          client_id: clientId,
          scope: scope,
          state: state,
          redirect_uri: redirectUri,
        });
        const response = await fetch(`/api/oauth-proxy?${queryParameters}`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tokens?.accessToken}`,
          },
        });

        const json: { location?: string } = await response.json();

        return json.location;
      } catch (_error) {
        captureException(_error, { tags: { fn: 'useAuthorization' } });
        throw _error;
      }
    },
    {
      enabled,
    }
  );

export { ApiError } from 'generated/api/core/ApiError';
