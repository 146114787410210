/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerOrder } from '../models/CustomerOrder';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MyOrdersService {

    /**
     * Retrieve the orders of a customer
     * Retrieves a Customer resource.
     * @returns CustomerOrder
     * @throws ApiError
     */
    public static portalOrdersCustomerItem({
        code,
    }: {
        /**
         * Resource identifier
         */
        code: string,
    }): CancelablePromise<Array<CustomerOrder>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customer_portal/my_customers/{code}/orders',
            path: {
                'code': code,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

}
