/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerPortalJWTAuth } from '../models/CustomerPortalJWTAuth';
import type { CustomerPortalJWTRefresh } from '../models/CustomerPortalJWTRefresh';
import type { CustomerPortalJWTResponse } from '../models/CustomerPortalJWTResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthenticationService {

    /**
     * Refresh the JWT using a refresh token
     * Refresh the JWT using a refresh token
     * @returns CustomerPortalJWTResponse Refresh successful
     * @throws ApiError
     */
    public static jwtRefresh({
        requestBody,
    }: {
        /**
         * Refresh token
         */
        requestBody: CustomerPortalJWTRefresh,
    }): CancelablePromise<CustomerPortalJWTResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customer_portal/jwt/refresh',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: missing or incorrect body`,
                401: `An authentication exception occurred: incorrect or expired refresh token`,
            },
        });
    }

    /**
     * Log in and create a JWT
     * Log in and create a JWT
     * @returns CustomerPortalJWTResponse Login successful
     * @throws ApiError
     */
    public static jwtToken({
        requestBody,
    }: {
        /**
         * Credentials
         */
        requestBody: CustomerPortalJWTAuth,
    }): CancelablePromise<CustomerPortalJWTResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customer_portal/jwt/token',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: missing or incorrect body`,
                401: `Invalid credentials: user does not exist or password is incorrect`,
            },
        });
    }

    /**
     * Generate redirect link for Oauth authentication
     * Generate redirect link for Oauth authentication
     * @returns void
     * @throws ApiError
     */
    public static getCustomerPortalOauthAuthAuthorize({
        responseType,
        clientId,
        scope,
        state,
        redirectUri,
    }: {
        responseType: any,
        clientId: any,
        scope?: any,
        state?: any,
        redirectUri?: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customer_portal/oauth/auth/authorize',
            query: {
                'response_type': responseType,
                'client_id': clientId,
                'scope': scope,
                'state': state,
                'redirect_uri': redirectUri,
            },
            errors: {
                302: `Authorization successful`,
                401: `Unauthorized or invalid oauth client`,
            },
        });
    }

}
