/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Request } from '../models/Request';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BrightplanService {

    /**
     * Create SSO link
     * Creates temporary sign-on link for Brightplan
     * @returns any Ok
     * @returns Request Request resource created
     * @throws ApiError
     */
    public static generateRequestCollection({
        requestBody,
    }: {
        /**
         * The new Request resource
         */
        requestBody: Request,
    }): CancelablePromise<{
        url?: string;
        hasAccount?: boolean;
    } | Request> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customer_portal/brightplan/generate_sso',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                403: `Invalid or missing API key`,
                404: `Not found`,
                422: `Unprocessable entity`,
            },
        });
    }

}
