/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnnualOverview } from '../models/AnnualOverview';
import type { CancelRequest } from '../models/CancelRequest';
import type { CustomerAppointment } from '../models/CustomerAppointment';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MyAppointmentsService {

    /**
     * Cancel a appointment
     * Cancels the user appointment
     * @returns string Ok
     * @returns CancelRequest CancelRequest resource created
     * @throws ApiError
     */
    public static cancelCancelRequestCollection({
        requestBody,
    }: {
        /**
         * The new CancelRequest resource
         */
        requestBody: CancelRequest,
    }): CancelablePromise<string | CancelRequest> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customer_portal/cancel_appointment',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Retrieve last years annual overview
     * Retrieves a Customer resource.
     * @returns AnnualOverview
     * @throws ApiError
     */
    public static myAnnualOverviewCustomerItem({
        code,
    }: {
        /**
         * Resource identifier
         */
        code: string,
    }): CancelablePromise<AnnualOverview> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customer_portal/my_customers/{code}/annual_overview',
            path: {
                'code': code,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Retrieve the future appointments of a customer
     * Retrieves a Customer resource.
     * @returns CustomerAppointment
     * @throws ApiError
     */
    public static myAppointmentsCustomerItem({
        code,
    }: {
        /**
         * Resource identifier
         */
        code: string,
    }): CancelablePromise<Array<CustomerAppointment>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customer_portal/my_customers/{code}/appointments',
            path: {
                'code': code,
            },
        });
    }

    /**
     * Retrieve the past appointments of a customer
     * Retrieves a Customer resource.
     * @returns CustomerAppointment
     * @throws ApiError
     */
    public static myPastAppointmentsCustomerItem({
        code,
    }: {
        /**
         * Resource identifier
         */
        code: string,
    }): CancelablePromise<Array<CustomerAppointment>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customer_portal/my_customers/{code}/appointments/past',
            path: {
                'code': code,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

}
