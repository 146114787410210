/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Customer_customer_portal_customer_list } from '../models/Customer_customer_portal_customer_list';
import type { Customer_customer_portal_customer_update } from '../models/Customer_customer_portal_customer_update';
import type { Customer_customer_portal_customer_view } from '../models/Customer_customer_portal_customer_view';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MyCustomersService {

    /**
     * Retrieves the collection of Customer resources.
     * Retrieves the collection of Customer resources.
     * @returns Customer_customer_portal_customer_list Customer collection
     * @throws ApiError
     */
    public static myCustomersCustomerCollection({
        page = 1,
        amount = 30,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        /**
         * The number of items per page
         */
        amount?: number,
    }): CancelablePromise<Array<Customer_customer_portal_customer_list>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customer_portal/my_customers',
            query: {
                'page': page,
                '_amount': amount,
            },
        });
    }

    /**
     * Retrieves a Customer resource.
     * Retrieves a Customer resource.
     * @returns Customer_customer_portal_customer_view Customer resource
     * @throws ApiError
     */
    public static myCustomerCustomerItem({
        code,
    }: {
        /**
         * Resource identifier
         */
        code: string,
    }): CancelablePromise<Customer_customer_portal_customer_view> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customer_portal/my_customers/{code}',
            path: {
                'code': code,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Replaces the Customer resource.
     * Replaces the Customer resource.
     * @returns Customer_customer_portal_customer_view Customer resource updated
     * @throws ApiError
     */
    public static putMyCustomerCustomerItem({
        code,
        requestBody,
    }: {
        /**
         * Resource identifier
         */
        code: string,
        /**
         * The updated Customer resource
         */
        requestBody: Customer_customer_portal_customer_update,
    }): CancelablePromise<Customer_customer_portal_customer_view> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/customer_portal/my_customers/{code}',
            path: {
                'code': code,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }

}
