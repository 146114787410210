import React, { ReactNode, useEffect } from 'react';
import styles from './Modal.module.css';
import 'wicg-inert';
import { createPortal } from 'react-dom';
import { faXmarkLarge } from '@fortawesome/pro-light-svg-icons/faXmarkLarge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let appElement: Element;
let modalContainer: Element;

export const setAppElement = (id: string) => {
  if (appElement || typeof window === 'undefined') {
    return;
  }

  const temporary = document.querySelector(id);

  if (temporary === null) {
    throw new Error('setAppElement should receive a valid id');
  }

  modalContainer = document.createElement('div');
  modalContainer.id = 'modal-container';
  appElement = temporary;

  appElement.parentElement?.append(modalContainer);
};

const setAppInert = (inert: boolean) => {
  if (appElement === undefined) {
    return;
  }

  if (inert) {
    return appElement.setAttribute('inert', '');
  }
  return appElement.removeAttribute('inert');
};

interface Properties {
  children: ReactNode;
  open: boolean;
  onRequestClose: () => void;
}

export const Modal = ({ open, children, onRequestClose }: Properties) => {
  useEffect(() => {
    setAppInert(open);
    return () => setAppInert(false);
  }, [open]);

  useEffect(() => {
    function onKeyDown(event: KeyboardEvent) {
      if (onRequestClose && event.key === 'Escape') {
        onRequestClose();
      }
    }

    document.addEventListener('keydown', onKeyDown, false);

    return () => document.removeEventListener('keydown', onKeyDown, false);
  }, [onRequestClose]);

  return createPortal(
    open ? (
      <React.Fragment>
        <div className={styles.blur} />
        <div className={styles.container}>
          <div aria-modal className={styles.modal}>
            <button type="button" onClick={onRequestClose} className={styles.closeButton}>
              <FontAwesomeIcon icon={faXmarkLarge} />
            </button>
            {children}
          </div>
        </div>
      </React.Fragment>
    ) : undefined,
    modalContainer
  );
};
