export function getQueryStringForOptions(options?: Record<string, string | number | string[] | number[] | undefined>) {
  if (!options) {
    return '';
  }

  const parameters = new URLSearchParams();

  for (const [key, value] of Object.entries(options || {})) {
    if (value) {
      parameters.append(key, value.toString());
    }
  }

  return parameters.toString();
}
